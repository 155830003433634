<template>
  <section class="section section__school" id="section2">
    <div class="section__wrapper">
      <div class="d-flex justify-content-between">
        <div class="title-with-icon mb-2">
          <div class="title-with-icon__icon title-with-icon__icon_programms-school"></div>
          <div class="title-with-icon__title">Программы Школы экспорта</div>
        </div>
        <div class="owl-nav cards-nav mb-2 d-none d-sm-flex">
          <button type="button" role="presentation" class="owl-prev disabled"><span aria-label="Previous">‹</span>
          </button>
          <button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
        </div>
      </div>
      <div class="section__school-grid">
        <div class="section__school-container-new">
          <div class="container-shadow">
            <div class="label-new">Новинка</div>
            <div class="owl-carousel owl-carousel__new">
              <div class="section__school-item">
                <div class="container-shadow_inner">
                  <div class="section__school-container-img">
                    <img src="/pic/icon-school-new.svg"/>
                  </div>
                  <div class="section__school-container-title">Новая программа</div>
                  <div class="section__school-container-subtitle">Акселерация. Экспортное наставничество</div>
                  <div class="section__school-container-text">
                    Новый уникальный продукт, направленный на индивидуальную проработку экспортного проекта компании совместно с наставником Школы экспорта РЭЦ! С нашими наставниками вы выведете свой экспорт на новый уровень.
                  </div>
                  <div class="hidden-text">
                    <div>Ознакомьтесь с <a href="/mentoring">новой</a> программой Школы экспорта РЭЦ</div>
                  </div>
                </div>
                <div><a href="/mentoring" class="link-target"><span>открыть</span></a></div>
              </div>
            </div>

          </div>
        </div>

        <div class="section__school-cards">
          <!--        <div class="owl-carousel section__school-cards-carousel">-->
          <div class="active column column-1">
            <div class="section__school-container_col section__school-container-seminars">
              <div class="container-shadow">
                <div class="container-shadow-bg"></div>
                <div class="container-shadow_inner">
                  <div class="section__school-container-img">
                    <img src="/pic/icon-school-seminars.svg"/>
                  </div>
                  <div class="section__school-container-title"><a href="/education#export">Экспортные семинары</a></div>
                  <div class="section__school-container-data">{{ school.seminars | formatNumber }}</div>
                  <div class="section__school-container-description">семинаров прошло</div>
                </div>
              </div>
            </div>
            <div class="section__school-container_col section__school-container-courses">
              <div class="container-shadow">
                <div class="container-shadow_inner">
                  <div class="section__school-container-img">
                    <img src="/pic/icon-school-courses.svg"/>
                  </div>
                  <div class="section__school-container-title">Дистанционные курсы и пособия</div>
                  <div class="show-data">
                    <div class="section__school-container-data">{{ school.distances | formatNumber }}</div>
                    <div class="section__school-container-description">человек прошло обучение</div>
                  </div>
                  <div class="hidden-data">
                    <ul class="section__school-container-links">
                      <li><a href="/education#online">Дистанционные курсы</a></li>
                      <li><a href="/documents">Учебные пособия</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="active column column-2">
            <div class="section__school-container_col section__school-container-acseleration">
              <div class="container-shadow">
                <div class="container-shadow_inner">
                  <div class="section__school-container-img">
                    <img src="/pic/icon-school-acseleration.svg"/>
                  </div>
                  <div class="section__school-container-title">Акселерационные программы</div>
                  <div class="show-data">
                    <div class="section__school-container-data">{{ school.accelerators | formatNumber }}</div>
                    <div class="section__school-container-description">компаний прошли акселерационные программы</div>
                  </div>
                  <div class="hidden-data">
                    <ul class="section__school-container-links">
                      <li><a href="/acceleration#export">Акселерация. Экспортный форсаж</a></li>
                      <li><a href="/acceleration#accelerator">Акселерация. Экспортный рост</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="section__school-container_col section__school-container-highschool">
              <div class="container-shadow" id="highschool">
                <div id="arrow-red" class="arrow-red"></div>
                <div id="arrow-red-down" class="arrow-red arrow-red_down"></div>
                <div class="container-shadow_inner">
                  <div class="section__school-container-img">
                    <img src="/pic/icon-school-highschool.svg"/>
                  </div>
                  <div class="section__school-container-title">Программы с ВУЗами</div>
                  <div class="show-data">
                    <div class="section__school-container-data">{{ school.inst_programs | formatNumber }}</div>
                    <div class="section__school-container-description">совместных программ с ВУЗами</div>
                  </div>
                  <div class="hidden-data">
                    <ul class="section__school-container-links">
                      <li><a href="/highschool">Основные сведения о взаимодействии с ВУЗами</a></li>
                      <li><a href="/highschool#hei">Совместные программы для бизнеса</a></li>
                      <li><a href="/highschool#stud">Совместные программы для студентов</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item column column-3">
            <div class="section__school-container_col section__school-container-default">
              <div class="container-shadow">
                <div class="container-shadow_inner">
                  <div class="section__school-container-img">
                    <img src="/pic/icon-school-default.svg"/>
                  </div>
                  <div class="section__school-container-title">
                    <a href="/teams">Обучение региональных команд</a>
                  </div>
                  <div class="show-data">
                    <div class="section__school-container-data">{{ school.region_commands }}</div>
                    <div class="section__school-container-description">число регионов-участников</div>
                  </div>
                  <div class="hidden-data">
                    <ul class="section__school-container-links" style="margin-top: 20px;">
                      <li><a href="/teams#basic">Основные сведения</a></li>
                      <li><a href="/teams#select">Порядок отбора субъектов РФ</a></li>
                      <li><a href="/teams#online">Организация обучения</a></li>
                      <li><a href="/teams#graduates">Выпускники программы</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--        </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SchoolPrograms",
  props: ['school'],
  data() {
    return {
      redAnimation: null,
      downAnimation: null
    }
  }
}
</script>

<style scoped>

</style>